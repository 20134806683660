import { get_query_string, isPC } from "../../../../common/utils/util";
import OfficeHomePage from "../../vue/OfficeHomePage";
import OfficeMuiscUpPage from "../../vue/OfficeMuiscUpPage";
import OfficeRechargePage from "../../vue/OfficeRechargePage";
import OfficeSelfPage from "../../vue/OfficeSelfPage";

/**
 * 官网页面
 */
export default {

    data() {
        return {
            isPc: isPC(),
            tabList: []
        }
    },

    async created() {
        let texts = [
            {
                text: "首页",
                isShow: true
            },
            // {
            //     text: `${i18n("game")}充值`,
            //     isShow: false
            // },
            // {
            //     text: "音乐上传",
            //     isShow: false
            // },
            // {
            //     text: "关于我们",
            //     isShow: true
            // },
            // {
            //     text: `${i18n("game")}介绍`,
            //     isShow: true
            // }
        ];
        let tabId = get_query_string("tabId");
        try {
            tabId = Number(tabId) || 0;
        } catch (e) {
            tabId = 0;
        }
        texts.forEach((item, idx) => {
            let tab = {
                type: idx,
                text: item.text,
                isShow: item.isShow,
                select: idx == tabId
            }
            this.tabList.push(tab);
        });
    },

    computed: {
        curTab() {
            return this.tabList.find(v => v.select);
        }
    },

    methods: {
        onclick(tab, idx) {
            if (this.curTab.type == tab.type) return;
            this.curTab.select = false;
            this.tabList[idx].select = true;
        }
    },

    components: {
        OfficeHomePage,
        OfficeMuiscUpPage,
        OfficeRechargePage,
        OfficeSelfPage
    }
}
